@keyframes loading {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.loading {
    position: relative;
    pointer-events: none;
}

.loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-animation: loading 3s 300ms linear 1 both;
    -moz-animation: loading 3s 300ms linear 1 both;
    -o-animation: loading 3s 300ms linear 1 both;
    animation: loading 3s 300ms linear 1 both;
    background: rgba(255, 255, 255, 0.5);
}
