// bootstrap variable overrides

// override main theme colors
$primary: #038dbe;
$secondary: #b2e2ff;
$dark: $primary;
//$body-bg: #f5e5ee;

// override pagination defaults - these are also used for the page size selector

$pagination-active-color: #000;
$pagination-hover-color: $pagination-active-color;
//$pagination-color: #b8b8b8;
$pagination-border-width: 0px;
$pagination-active-bg: initial;

//override breadcrumb defaults

$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-bg: none;
$breadcrumb-divider: '>';

@import "../../../node_modules/bootstrap/scss/variables";
