//.page-link {
//  transition: background-color 250ms linear;
//}

.pagination {
  display: flex;
  @include list-unstyled();
  // TODO make separate class?
  margin: 0 (-$pagination-padding-x);
}

// TODO include in list?
.page-size-label {
  color: $pagination-active-color;
}

.page-link, .page-size-label {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  line-height: $pagination-line-height;
}

@mixin show-underline($background-color) {
  &::after {
    background-color: $pagination-active-color;
    width: 100%;
    transition: width 250ms ease-in-out;
  }
}


.page-link {
  color: $pagination-color;
  //background-color: $pagination-bg;

  &::after {
    width: 0;
    content: '';
    display: block;
    margin: 0 auto;
    height: 2px;
    background: transparent;
  }

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;

    @include show-underline($pagination-active-color);
  }

  &:focus {
    z-index: 3;
  }
}

// don't show underline on next / previous buttons
.next, .prev, .first, .last {
  .page-link {
    &::after {
      content: none;
    }
  }
}

.page-item {
  //&:first-child {
  //  .page-link {
  //    padding-left: 0;
  //  }
  //}
  //
  //&:last-child {
  //  .page-link {
  //    padding-right: 0;
  //  }
  //}

  &.active .page-link {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;

    @include show-underline($pagination-active-color);
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, 0);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, 0);
}

